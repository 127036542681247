// Alert variables ---------- //

$alert-icons: (
  success: "check_circle",
  warning: "warning",
  error: "error",
  info: "info",
  emergency: "error",
);

$alert-padding-left: units($theme-alert-padding-x) +
  units($theme-alert-bar-width);

.usa-alert {
  @include typeset($theme-alert-font-family);
  @include border-box-sizing;
  background-color: color("base-lightest");
  background-position: $alert-padding-left units($theme-alert-padding-x - 1);
  background-repeat: no-repeat;
  background-size: units($theme-alert-icon-size);
  padding-bottom: units(2);
  padding-left: $alert-padding-left;
  padding-right: units($theme-alert-padding-x);
  padding-top: units($theme-alert-padding-x);
  position: relative;

  * + & {
    margin-top: units(2);
  }

  // TODO: why is this not simply a border?
  &::before {
    background-color: color("base-light");
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: units($theme-alert-bar-width);
  }

  > .usa-list,
  .usa-alert__body > .usa-list {
    padding-left: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.usa-alert__icon {
  display: table-cell;
  padding-right: units($theme-alert-bar-width);
}

.usa-alert__body {
  display: table-cell;
  vertical-align: top;
}

.usa-alert__heading {
  @include typeset($theme-alert-font-family, "lg", 1);
  margin-top: 0;
  margin-bottom: units(1);
}

.usa-alert__text {
  @include u-margin-y(0);

  a {
    @include typeset-link;
  }
}

.usa-alert__text:only-child {
  margin-bottom: units($theme-alert-bar-width);
  padding-top: units(0.5);
}

@each $name, $icon in $alert-icons {
  .usa-alert--#{$name} {
    @include alert-status-styles($name, $icon);
  }
}

.usa-alert--slim {
  @include alert-slim-styles;
}

.usa-alert--no-heading {
  background-position: $alert-padding-left
    calc(#{units($theme-alert-padding-x)} - #{units(0.5)});
}

.usa-alert--validation {
  background-size: units(3);
  background-position: $alert-padding-left
    calc(#{units($theme-alert-padding-x)} - #{units(2px)});

  .usa-alert__body {
    padding-left: units(5);
  }

  .usa-checklist {
    margin-top: units(2);
  }
}
