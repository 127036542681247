@mixin alert-status-styles($name, $icon) {
  $bgcolor: if($name != "emergency", "#{$name}-lighter", $name);
  $banner-text-color-token: get-color-token-from-bg(
    $bgcolor,
    $context: "Alert text"
  );
  $icon-path: if(
    $banner-text-color-token == "ink",
    "usa-icons/#{$icon}",
    "alerts/#{$icon}-white"
  );
  @include add-background-svg($icon-path);
  background-color: color($bgcolor);
  color: color($banner-text-color-token);

  &::before {
    background-color: color($name);
  }

  .usa-alert__body {
    padding-left: units($theme-alert-icon-size) + units($theme-alert-padding-x);
  }

  .usa-link {
    @include set-link-from-bg(
      $bgcolor,
      $theme-alert-link-reverse-color,
      $theme-alert-link-color,
      $context: "Alert (#{$name})"
    );
  }

  &.usa-alert--no-icon {
    background-image: none;

    .usa-alert__body {
      padding-left: 0;
    }
  }
}
